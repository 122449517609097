module.exports = [{
      plugin: require('/codebuild/output/src591377763/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src591377763/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-59019499-4"},
    },{
      plugin: require('/codebuild/output/src591377763/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
